<template>
  <div >
    <el-row :gutter="20" v-loading="loadingData">
      <el-col v-if="!self_scan" :span="8">
        <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.dealProduct')" filterable clearable
            :size="size" class="w-100">
              <el-option v-for="(dealProduct,index) in list" :key="'dealProduct-' + index" 
                :label="labelGenerate(dealProduct)" 
                :value="dealProduct.id">
              </el-option>
        </el-select>
      </el-col>
      <el-col v-if="!self_scan && show_package" :span="8">
        <el-select :value="selected" @input="dispatchPackage" :placeholder="$t('message.packages')" filterable clearable
            :size="size" :disabled="disabled" class="w-100">
              <el-option v-for="(pack, index) in package_list" :key="'packages-' + index" 
                :label="labelGenerateForPackage(pack)" 
                :value="pack.id">
              </el-option>
        </el-select>
      </el-col>
      <el-col v-if="self_scan" :span="16">
        <el-input id="prod" class="w-100" @input="searchProductDebounce" :placeholder="$t('message.scan')" v-model="barcode_search" :size="size"></el-input>
      </el-col>
      <el-col :span="4">
        <el-switch
          v-model="self_scan"
          :active-text="$t('message.scan')"
          :inactive-text="$t('message.select')">
        </el-switch>
      </el-col>
      <el-col :span="4" v-if="from_filial_id && to_filial_id && !disabled" style="text-align: right;">
        <el-button type="primary" size="medium" @click="showDirectionProducts()">{{$t('message.products')}}</el-button>
      </el-col>
    </el-row>

    <el-drawer
      :with-header="false" 
      class="bg-se"
      size="90%" 
      :visible.sync="drawerProductList"
      :append-to-body="true"
      ref="drawerProductList"
      @opened="drawerOpened('drawerProductListChild')"
      @closed="drawerClosed('drawerProductListChild')"
    >
      <filial-products
          ref="drawerProductListChild"
          @mergeToProductList="mergeToProductList"
          :payload_for_prod_list="payload_for_prod_list"
          drawer="drawerProductList"
          > 
      </filial-products>
    </el-drawer>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  import select_create_drawer from "@/utils/mixins/select_create_drawer";
  import filialProducts from "@/views/containers/components/filial-products";

  export default {
    mixins: [select_create_drawer],
    components: {
      filialProducts
    },
    props: {
      size: {
        default: 'large'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      is_reciever: {
        default: false
      },
      disabled:{
        default: false
      },
      deal_id:{
        default: null
      },
      all_client_id:{
        default: null
      },
      from_filial_id:{
        default: null
      },
      to_filial_id:{
        default: null
      },
      scan:{
        default: true
      },
      show_package:{
        default: true
      }
    },
    data() {
      return {
        selected: null,
        barcode_prod: null,
        barcode_search: '',
        list: [],
        package_list: [],
        self_scan: false,
        drawerProductList: false,
        payload_for_prod_list: {},
        loadingData: false,
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      },
      scan:{
        handler: function() {
            this.self_scan = JSON.parse(JSON.stringify(this.scan));
        },
        immediate: true
      },
      watchFilials:{
        handler: function(val) {
          this.list = [];
          this.package_list = [];
          if(this.from_filial_id && this.to_filial_id && this.self_scan){
            document.getElementById("prod").focus();
          }
          if(this.from_filial_id && this.to_filial_id && !this.self_scan){
            let query = {deal_id: this.deal_id, from_filial_id: this.from_filial_id, to_filial_id: this.to_filial_id, all_client_id: this.all_client_id};

            this.updatePackages({...query, store_in_realtion: 'Filial', store_in_id: this.from_filial_id})
              .then(res => {
                this.package_list = JSON.parse(JSON.stringify(this.packages));
              }).catch(err => {
              });
            this.updateInventory({...query, recieve_from_client: true, type: 'container'})
              .then(res => {
                this.dealProducts.forEach(element => {
                  if(element.remainder > 0){
                    this.list.push(JSON.parse(JSON.stringify(element)));
                  }
                });
              })
              .then(res => {
                if(this.barcode_prod){
                  this.addBarcodeProd();
                }
              }).catch(err => {});
          }else{
            this.list = [];
            this.package_list = [];
          }

        },
        immediate: true,
        deep: true
      },
    },
    
    computed: {
      ...mapGetters({
        dealProducts: 'dealProducts/filialDealProducts',
        packages: 'packageForProductCirculations/inventory',
      }),
      watchFilials(){
        return this.from_filial_id + this.to_filial_id + this.all_client_id + this.deal_id + this.self_scan + 'aa';
      }
    },
    methods: {
      ...mapActions({
        updateInventory: 'dealProducts/filialDealProducts',
        getProductByBarcode: 'productCirculations/getProductByBarcode',
        updatePackages: 'packageForProductCirculations/inventory',
      }),

      labelGenerate(dealProduct){
        let prod_name = dealProduct.product ? dealProduct.product.name : '';
        let prod_comment = dealProduct.comment ? (' (' + dealProduct.comment + ') ') : '';
        let name = prod_name + prod_comment + ' ' +' №'+dealProduct.deal_id;
        return name;
      },
      labelGenerateForPackage(pack){
        let cont_name = pack.containerType ? pack.containerType.name : '';
        return '№'+ pack.id + ' ' + cont_name;
      },
      async addBarcodeProd(){
        await this.dispatch(this.barcode_prod);
        this.barcode_search = '';
        this.barcode_prod = null;
      },
      stringContainString(arr) {
        return arr[1].split('').every(function(letter) {
            return arr[0].indexOf(letter) != -1;
          });
      },
      searchProductDebounce: _.debounce(
        function(val) {
          this.loadingData = true;
          this.searchProduct(val);
        }, 200),  
        
      async searchProduct(val) {
          let upper_barcode = val.toUpperCase();
          if(upper_barcode && this.stringContainString([upper_barcode, 'P-D-C']) || this.stringContainString([upper_barcode, 'P-F'])){
            await this.getProductByBarcode({search: upper_barcode})
              .then(async res => {
                let response = res.data.result;
                if(response.success){ 
                  await this.findProd(val, response.data.type);
                  if(response.data.type === 'product'){
                    this.barcode_prod = JSON.parse(JSON.stringify(response.data.id));
                    this.barcode = JSON.parse(JSON.stringify(response.data.barcode));
                    if(this.deal_id && response.data.deal_id === this.deal_id){
                      this.addBarcodeProd();
                    }
                    this.$emit('get_selected_product', {product: this.dealProducts.find(el => el.id === response.data.id), barcode: response.data.barcode, type: 'product'})
                  }
                  if(response.data.type === 'package'){
                    this.dispatchPackage(response.data.id);
                    this.barcode = JSON.parse(JSON.stringify(response.data.barcode));
                  }
                  this.barcode_search = '';                            
                }else{
                  this.barcode_search = '';                            
                  this.$error_message(res);
                }
              })
              .catch(err => {
                this.barcode_search = '';                            
              }); 
            this.loadingData = false;
          }else if(!upper_barcode){
            this.barcode_search = '';
            this.loadingData = false;
          }else{
            this.barcode_search = '';                            
            this.$notify({
                title: this.$t('message.warning'),
                type: "warning",
                offset: 130,
                message: this.$t('message.invalid_barcode')
            });
            this.loadingData = false;
          }
      },
      async findProd(barcode, prod_type){
        let query = {deal_id: this.deal_id, from_filial_id: this.from_filial_id, to_filial_id: this.to_filial_id, all_client_id: this.all_client_id};
        if(prod_type === 'package'){
          await this.updatePackages({...query, store_in_realtion: 'Filial', store_in_id: this.from_filial_id, barcode: barcode})
            .then(res => {
              this.package_list = JSON.parse(JSON.stringify(this.packages));
            }).catch(err => {
            });
        }
        if(prod_type === 'product'){
          await this.updateInventory({...query, recieve_from_client: true, type: 'container', barcode: barcode})
            .then(res => {
              this.dealProducts.forEach(element => {
                this.list = [];
                if(element.remainder > 0){
                  this.list.push(JSON.parse(JSON.stringify(element)));
                }
              });
            }).catch(err => {});
        }
        return 'aa';
      },

      showDirectionProducts(){
        this.payload_for_prod_list = {deal_id: this.deal_id, from_filial_id: this.from_filial_id, to_filial_id: this.to_filial_id, all_client_id: this.all_client_id, show_package: this.show_package, is_reciever: this.is_reciever};
        this.drawerProductList = true;
      },
      mergeToProductList(listOfSelected){
        listOfSelected.forEach(element => {
          this.$emit('get_selected_product', {product: element, barcode: '', type: element.type, from_list: true});
        });
      },
      dispatchPackage(e) {
          this.$emit('get_selected_product', {product: this.package_list.find(el => el.id === e), barcode: this.barcode, type: 'package'})
      },
      dispatch(e) {
        if(e && this.list.length > 0){
          this.$emit('get_selected_product', {product: this.list.find(el => el.id === e), barcode: this.barcode, type: 'product'})
        }
        this.barcode = '';
      }
    },
  }

</script>
